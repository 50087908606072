<template>
  <div>
    <!-- The page has no content definition -->
    <errorHandeling
      v-if="Object.keys(page.ContentDefinition).length == 0 && !editMode"
      :snackbarText="$t('This page has not been configured yet')"
      :buttons="[
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ]"
      snackbarColor="info"
      snackbarTimout="-1"
      snackbarIcon="mdi-information"
    />
    <!-- List repeating page items -->
    <template v-else-if="!editMode">
      <repeating-content-buttons
        ref="RepeatingContentButtons"
        :page="page"
        :linkedPages="linkedPages"
        :tableSortBy="tableSortBy"
        :tableSortDescending="tableSortDescending"
        :searchValue="searchValue"
        :content="content"
        @getData="$emit('getData')"
        @getPageFiltersQueryString="getPageFiltersQueryString()"
        @getContent="getContent()"
        @contentReset="content = null"
      />

      <v-card>
        <v-card-title>
          <v-container fluid>
            <v-row>
              <v-col class="pa-0 mb-3" cols="12" sm="10">
                <h3 class="mt-3">{{ page.Name[currentLanguage] }}</h3>
              </v-col>
              <v-col class="pa-0 mb-3" cols="12" sm="2">
                <v-text-field
                  class="pt-0"
                  v-if="page.AddSearchField"
                  :label="$t('Search')"
                  clearable
                  append-icon="mdi-magnify"
                  @click:clear="onSearchCleared()"
                  @keyup="searchContent"
                  v-model="searchValue"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="filters-row">
              <!-- filters -->
              <v-col
                class="pa-0 pr-6"
                cols="12"
                sm="2"
                v-for="(item, key, index) in getPageContentDefinition"
                :key="index"
              >
                <v-select
                  :items="getBooleanValues()"
                  v-if="item['Type'] == 'boolean'"
                  clearable
                  :label="getTranslatedItemTitle(item)"
                  item-value="value"
                  item-text="label"
                  @change="filterChanged($event, key)"
                >
                </v-select>
                <v-select
                  :items="item.Options"
                  v-else-if="item['Type'] == 'dropdown'"
                  clearable
                  :label="getTranslatedItemTitle(item)"
                  item-value="Id"
                  item-text="Value"
                  @change="filterChanged($event, key)"
                ></v-select>
                <date-picker
                  name="dateFilterMenu"
                  v-else-if="item['Type'] == 'datepicker'"
                  :lableName="item.Title"
                  :dateOptions="{
                    dateClearable: true,
                    dateDisabled: false,
                    dataFieldValidation: item.FieldValidation,
                    dateAutoSelectCurrent: false,
                    dateRules: null,
                    dateIsFilter: true,
                  }"
                  :dateValue="item.Value"
                  @clearDate="filterChanged(undefined, key)"
                  @changeDatePicker="filterChanged($event, key)"
                />

                <time-picker-component
                  :lableName="item.Title"
                  v-else-if="item['Type'] == 'time'"
                  :timeValue="timeFilterValue"
                  :timeOptions="{
                    timeClearable: true,
                    timeDisabled: false,
                    timeFieldValidation: item.FieldValidation,
                    timeRules: null,
                    showSeconds: item.ShowSeconds,
                  }"
                  @changedTime="filterChanged($event, key)"
                  @clearTime="filterChanged(undefined, key)"
                />
                <v-layout align-center v-else-if="item['Type'] == 'content'">
                  <v-autocomplete
                    :ref="'autocomplete' + key"
                    :items="contentFilterSearchResults[key]"
                    :loading="searchingContentFilters[key]"
                    :search-input.sync="searchContentFilterModels[key]"
                    :placeholder="
                      item.LoadItemsWhenCreated ? '' : $t('Search') + '...'
                    "
                    :multiple="item.MultipleItems"
                    :return-object="item.MultipleItems"
                    :chips="item.MultipleItems"
                    :deletable-chips="item.MultipleItems"
                    :item-text="showItem"
                    item-value="_id"
                    clearable
                    :label="getTranslatedItemTitle(item)"
                    hide-no-data
                    @keyup="searchContentFilter(item.LinkedPage, key)"
                    @change="filterChanged($event, key)"
                    height="32px"
                    small-chips
                  >
                  </v-autocomplete>
                </v-layout>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <div v-if="page.Visualization == 'rows' || !page.Visualization">
          <v-row
            v-for="(item, index) in content"
            :key="item.DisplayLabel"
            no-gutters
          >
            <v-col
              subheading
              lg="2"
              sm="3"
              xs="3"
              class="pt-4 pl-3 pb-3"
              v-if="
                userIsAdministrator ||
                (page.ShowSortButtons &&
                  $store.getters.hasUserRoleForPageItem({
                    role: 1,
                  }))
              "
            >
              <v-btn
                class="edit-page-action-button sort-button"
                text
                small
                icon
                color="grey"
                @click="moveUp(index)"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>

              <v-btn
                class="edit-page-action-button sort-button"
                text
                small
                icon
                color="grey"
                @click="moveDown(index)"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="
                userIsAdministrator ||
                (page.ShowSortButtons &&
                  $store.getters.hasUserRoleForPageItem({
                    role: 1,
                  }))
              "
              lg="8"
              sm="6"
              xs="4"
              class="text-xs-right align-center"
            >
              <v-layout align-center fill-height>
                <v-list-item-title
                  v-html="item.DisplayLabel"
                ></v-list-item-title>
              </v-layout>
            </v-col>
            <v-col v-else lg="10" sm="9" xs="7" class="pl-3 text-xs-right">
              <v-list-item-title v-html="item.DisplayLabel"></v-list-item-title>
            </v-col>
            <v-col lg="2" sm="3" xs="4" class="pa-3 subheading text-right">
              <router-link
                :to="{
                  name: 'editPageAddEditItem',
                  params: { id: page._id.$oid, itemId: item._id.$oid },
                }"
              >
                <v-btn icon color="orange">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </router-link>

              <v-btn
                icon
                color="red"
                @click="setDeletingItem(page._id.$oid, item._id.$oid, index)"
                :loading="loadinItemToDelete[index]"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider v-if="index + 1 != content.length"></v-divider>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="page.Visualization == 'table'">
          <v-data-table
            :headers="tableHeaders"
            :header-props="{ sortByText: $t('Sort by') }"
            :items="tableRows"
            :no-data-text="$t('No data available')"
            :footer-props="{
              itemsPerPageOptions: [-1],
              pageText: '',
              nextIcon: '',
              prevIcon: '',
            }"
            item-key="Value"
            :options.sync="options"
            :custom-sort="customSort"
            @click:row="handleClick"
            @click:row.ctrl="handleClick"
            @click.native.ctrl="handleClick"
            :class="page.MakeRowsClickable ? 'cursor-pointer' : ''"
          >
            <template
              v-for="(tableHeader, index) in tableHeaders"
              v-slot:[`item.${tableHeader.value}`]="{ item }"
            >
              <div v-if="item[tableHeader.value]" :key="index">
                <template v-if="item[tableHeader.value].AddToTableView">
                  <template v-if="item[tableHeader.value].Type === 'dropdown'">
                    <v-chip
                      v-if="
                        item[tableHeader.value].ShowAsLabel &&
                        getTableDropdown(item[tableHeader.value]) != ''
                      "
                      :color="getTableDropdown(item[tableHeader.value], true)"
                    >
                      {{ getTableDropdown(item[tableHeader.value]) }}
                    </v-chip>
                    <div v-else>
                      {{ getTableDropdown(item[tableHeader.value]) }}
                    </div>
                  </template>

                  <template
                    v-else-if="item[tableHeader.value].Type === 'boolean'"
                  >
                    {{
                      item[tableHeader.value].Value
                        ? $i18n.translate("Yes")
                        : $i18n.translate("No")
                    }}
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'content'"
                  >
                    <!-- <v-chip
                    v-if="item[tableHeader.value].ShowAsLabel"
                    :color="
                    item[tableHeader.value].ColorLinkedPage.value
                    .Value
                              "
                              >
                              {{ item[tableHeader.value] }}
                            </v-chip> -->
                    <v-layout fill-height align-center justify-start>
                      <template v-if="item[tableHeader.value].MultipleItems">
                        <template
                          v-for="(val, index) in getTableContentItems(
                            item[tableHeader.value]
                          )"
                        >
                          {{ getValue(index, val, item[tableHeader.value]) }}
                          <v-btn
                            :key="index"
                            v-if="
                              item[tableHeader.value].ShowLinkToItems &&
                              $helpers.checkGoToLinkedContent(
                                userIsAdministrator,
                                item[tableHeader.value].LinkedContent[index],
                                item[tableHeader.value],
                                selectedWebsite.Pages
                              )
                            "
                            class="ml-1"
                            right
                            x-small
                            icon
                            @click="
                              $helpers.goToLinkedContentPage(
                                item[tableHeader.value],
                                item[tableHeader.value].LinkedPage.$oid,
                                item[tableHeader.value].LinkedContent[index]
                                  .$oid
                              )
                            "
                          >
                            <v-icon> mdi-arrow-right </v-icon>
                          </v-btn>
                        </template>
                      </template>
                      <template v-else>
                        <template v-if="item[tableHeader.value].DisplayLabels">
                          {{
                            item[tableHeader.value].DisplayLabels[
                              currentLanguage
                            ] || item[tableHeader.value].DisplayLabels["Nl"]
                          }}
                        </template>
                        <p class="mb-0" v-else>
                          {{ item[tableHeader.value].ValueSort }}
                        </p>
                        <v-btn
                          v-if="
                            item[tableHeader.value].ShowLinkToItems &&
                            $helpers.checkGoToLinkedContent(
                              userIsAdministrator,
                              item[tableHeader.value].LinkedContent,
                              item[tableHeader.value],
                              selectedWebsite.Pages
                            )
                          "
                          class="ml-1"
                          right
                          x-small
                          icon
                          @click="
                            $helpers.goToLinkedContentPage(
                              item[tableHeader.value]
                            )
                          "
                        >
                          <v-icon> mdi-arrow-right </v-icon>
                        </v-btn>
                      </template>
                    </v-layout>
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'number'"
                  >
                    {{ getTableNumber(item[tableHeader.value]) }}
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'colorpicker'"
                  >
                    {{ item[tableHeader.value].Value }}
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'datepicker'"
                  >
                    {{ getTableDatepicker(item[tableHeader.value]) }}
                  </template>
                  <template v-else-if="item[tableHeader.value].Type === 'url'">
                    <v-layout fill-height align-center justify-start>
                      <v-img
                        contain
                        max-height="16"
                        max-width="16"
                        :src="getFavicon(item[tableHeader.value])"
                        class="mr-2"
                      >
                      </v-img>
                      <p
                        class="mb-0"
                        :class="
                          item[tableHeader.value].Value
                            ? 'text-decoration-underline cursor-p'
                            : ''
                        "
                        @click="openInNewTab(item[tableHeader.value].Value)"
                      >
                        {{ getDomainUrl(item[tableHeader.value].Value) }}
                      </p>
                    </v-layout>
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'files'"
                  >
                    <div>
                      <v-layout
                        fill-height
                        align-center
                        justify-start
                        v-for="(file, index) in getAllFilesFromItem(
                          item[tableHeader.value].Value
                        )"
                        :key="index"
                      >
                        <p class="mb-0">{{ file }}</p>

                        <v-btn
                          color="primary"
                          icon
                          x-small
                          class="ml-1"
                          @click="$set(showPreview, file, true)"
                          v-if="
                            !showPreview[file] &&
                            (file.includes('.pdf') || file.includes('.mp4'))
                          "
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn
                          :loading="isDownloading[file]"
                          color="primary"
                          icon
                          x-small
                          @click="downloadFile(file)"
                        >
                          <v-icon>mdi-file-download</v-icon>
                        </v-btn>
                        <pageElementFilesDialog
                          :key="page.WebsiteId.$oid + file"
                          :websiteId="page.WebsiteId.$oid"
                          :showPreviewItem="file"
                          :showPreviewProp="showPreview[file]"
                          @update:showPreview="$set(showPreview, file, $event)"
                        />
                      </v-layout>
                    </div>
                  </template>
                  <template v-else>
                    {{ getTableText(item[tableHeader.value]) }}
                  </template>
                </template>
              </div>
            </template>
            <template v-slot:[`item.sort`]="{ index }">
              <v-btn
                class="edit-page-action-button sort-button"
                text
                small
                icon
                color="grey"
                @click="moveUp(index)"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>

              <v-btn
                class="edit-page-action-button sort-button"
                text
                small
                icon
                color="grey"
                @click="moveDown(index)"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item, index }">
              <router-link
                v-if="
                  userIsAdministrator ||
                  ($store.getters.hasUserRoleForPageItem({
                    role: 1,
                  }) &&
                    page.ShowCopyBtnForAllUsers)
                "
                :to="{
                  name: 'editPageAddCopyItem',
                  params: {
                    id: page._id.$oid,
                    itemId: item._id.$oid,
                    isCopy: true,
                  },
                }"
              >
                <v-btn icon color="primary">
                  <v-icon>mdi-plus-box-multiple</v-icon>
                </v-btn>
              </router-link>

              <router-link
                v-if="
                  userIsAdministrator ||
                  ($store.getters.hasUserRoleForPageItem({
                    role: 0,
                  }) &&
                    !$store.getters.hasUserRoleForPageItem({
                      role: 1,
                    }))
                "
                :to="{
                  name: 'editPageViewItem',
                  params: {
                    id: page._id.$oid,
                    itemId: item._id.$oid,
                    isDetail: true,
                  },
                }"
                ><v-btn icon color="primary">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </router-link>
              <router-link
                v-if="
                  userIsAdministrator ||
                  $store.getters.hasUserRoleForPageItem({
                    role: 1,
                  })
                "
                :to="{
                  name: 'editPageAddEditItem',
                  params: { id: page._id.$oid, itemId: item._id.$oid },
                }"
              >
                <v-btn icon color="orange">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </router-link>

              <v-btn
                v-if="
                  userIsAdministrator ||
                  $store.getters.hasUserRoleForPageItem({
                    role: 2,
                  })
                "
                icon
                color="red"
                @click="setDeletingItem(page._id.$oid, item._id.$oid, index)"
                :loading="loadinItemToDelete[index]"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </v-card>

      <infinite-loading
        slot="append"
        force-use-infinite-wrapper=".el-table__body-wrapper"
        @infinite="getContent"
        ref="infiniteLoading"
      >
        <div slot="spinner"><preloader></preloader></div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </template>

    <!-- Edit repeating objects content definition -->
    <template v-if="userIsAdministrator && editMode">
      <v-card class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Visualization") }}</h3>

          <v-radio-group v-model="page.Visualization" mandatory>
            <v-radio :label="$t('Rows')" value="rows"></v-radio>
            <v-radio :label="$t('Table')" value="table"></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Edit content definition") }}</h3>
        </v-card-text>

        <v-form ref="contentDefinitionform" lazy-validation>
          <template v-for="(item, index) in page.ContentDefinition">
            <v-layout
              :key="index"
              class="custom-overview-row mt-sm-0 mt-md-4"
              wrap
            >
              <v-row>
                <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                  <v-row no-gutters>
                    <v-layout column class="max-width-layout">
                      <v-btn
                        text
                        small
                        icon
                        color="grey"
                        @click="moveUpContentDefinition(index)"
                      >
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-btn>

                      <v-btn
                        text
                        small
                        icon
                        color="grey"
                        @click="moveDownContentDefinition(index)"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </v-layout>
                    <h4
                      class="mt-4 text-right text-sm-left text-md-left text-lg-left text-xl-left pr-0 pr-sm-4 pr-md-4 pr-lg-4 pr-xl-4"
                    >
                      {{ index }}
                    </h4>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                  <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-col cols="8" sm="5" md="5" lg="5" class="pl-2">
                      <configLanguageSelector
                        :colOption="{
                          colsDropdown: 3,
                          colsText: 9,
                        }"
                        :itemData="item"
                        @changeLanguage="selectedLang[index] = $event"
                        @changeTranslatedTitle="item.TranslatedTitle = $event"
                      />

                      <template v-if="item.Type == 'content'">
                        <v-select
                          :items="getSharedPagesNotAssigned()"
                          single-line
                          v-model="item.LinkedPage"
                          item-value="_id"
                          :label="$t('Select a page')"
                          class="altSelect"
                          @change="getContentFromLinkedPage(item.LinkedPage)"
                        >
                          <template slot="selection" slot-scope="data">
                            <div class="input-group__selections__comma">
                              {{ data.item.Name[currentLanguage] }}
                            </div>
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ data.item.Name[currentLanguage] }}
                          </template>
                        </v-select>
                      </template>
                      <template v-else-if="item.Type == 'croppedimage'">
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            md="12"
                            lg="6"
                            xl="6"
                          >
                            <v-text-field
                              :label="$t('Width in pixels')"
                              v-model="item.Width"
                              class="mr-md-3 mr-lg-3 mr-xl-3"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            md="12"
                            lg="6"
                            xl="6"
                          >
                            <v-text-field
                              :label="$t('Height in pixels')"
                              v-model="item.Height"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else-if="item.Type == 'colorpicker'">
                        <v-color-picker
                          v-model="item.Value"
                          class="ml-4"
                          dot-size="25"
                          mode="hexa"
                          swatches-max-height="200"
                        ></v-color-picker>
                      </template>

                      <template v-if="item.Type == 'dropdown'">
                        <v-row no-gutters>
                          <v-col cols="12" class="mb-4">
                            <b>
                              <i> {{ $t("Configure dropdown options") }}: </i>
                            </b>
                          </v-col>
                          <v-col class="mb-4">
                            <v-chip
                              v-for="(option, optionIndex) in item.Options"
                              :key="optionIndex"
                              close
                              @click:close="removeOption(index, optionIndex)"
                            >
                              {{ option.Value }}
                            </v-chip>
                          </v-col>
                          <v-col cols="12">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-text-field
                                  :label="$t('Option name')"
                                  v-model="newOptionName"
                                  :error="addOptionFieldHasError"
                                  :rules="addOptionFieldRule"
                                  class="no-styling ml-1"
                                  hide-details="auto"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="mb-4">
                                <v-btn
                                  class="mt-3"
                                  color="success"
                                  @click="addOption(index)"
                                >
                                  {{ $t("Add option") }}
                                  <v-icon right>mdi-plus</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col cols="4" sm="2" md="2" lg="2" class="pl-3">
                      <h4
                        class="mt-4 mb-4 text-right text-sm-left text-md-left text-lg-left text-xl-left pr-0 pr-md-4 pr-lg-4 pr-xl-4"
                      >
                        {{ item.Type }}
                      </h4>
                      <p
                        v-if="item.Type == 'colorpicker'"
                        class="mt-6 mb-4 ml-4"
                      >
                        {{ $t("default value") }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4">
                      <v-switch
                        v-if="
                          item.Type == 'text' ||
                          item.Type == 'richtext' ||
                          item.Type == 'image' ||
                          item.Type == 'croppedimage' ||
                          item.Type == 'gallery'
                        "
                        v-model="item.Translate"
                        class="mt-3 ml-2 mb-0"
                        light
                        v-bind:label="$t('Translate item')"
                      ></v-switch>

                      <v-switch
                        v-if="
                          item.Type == 'image' ||
                          item.Type == 'croppedimage' ||
                          item.Type == 'gallery'
                        "
                        v-model="item.AddAltTags"
                        class="mt-0 ml-2"
                        :label="$t('Add alt tags')"
                      >
                      </v-switch>

                      <v-switch
                        v-if="item.Type == 'content'"
                        class="mt-0 ml-2"
                        v-model="item.LoadItemsWhenCreated"
                        :label="$t('Load items when created')"
                      ></v-switch>
                      <v-switch
                        v-if="item.Type == 'content'"
                        class="mt-0 ml-2"
                        v-model="item.MultipleItems"
                        :label="$t('Multiple items')"
                      ></v-switch>
                      <v-switch
                        v-if="item.Type == 'content'"
                        class="mt-0 ml-2"
                        v-model="item.ShowLinkToItems"
                        :label="$t('Show link to item(s) in table overview')"
                      ></v-switch>

                      <v-switch
                        v-if="item.Type == 'time'"
                        class="mt-0 ml-2"
                        v-model="item.ShowSeconds"
                        :label="$t('Show seconds')"
                      ></v-switch>

                      <v-switch
                        v-if="item.Type != 'custom'"
                        class="mt-0 ml-2"
                        v-model="item.ReadOnly"
                        :label="$t('Read-only')"
                      ></v-switch>

                      <v-switch
                        v-if="
                          page.Visualization == 'table' &&
                          (item.Type == 'text' ||
                            item.Type == 'richtext' ||
                            item.Type == 'datepicker' ||
                            item.Type == 'time' ||
                            item.Type == 'dropdown' ||
                            item.Type == 'boolean' ||
                            item.Type == 'content' ||
                            item.Type == 'number' ||
                            item.Type == 'custom' ||
                            item.Type == 'url' ||
                            item.Type == 'colorpicker' ||
                            item.Type == 'files')
                        "
                        v-model="item.AddToTableView"
                        class="mt-0 ml-2"
                        :label="$t('Add to table view')"
                      >
                      </v-switch>

                      <v-switch
                        v-if="
                          item.Type == 'content' ||
                          item.Type == 'dropdown' ||
                          item.Type == 'boolean' ||
                          item.Type == 'datepicker' ||
                          item.Type == 'time'
                        "
                        v-model="item.ShowFilter"
                        class="mt-0 ml-2"
                        :label="$t('Show filter')"
                      >
                      </v-switch>

                      <!--<v-switch
                                v-if="item.Type == 'text'"
                                v-model="item.Encrypted"
                                class="mt-0 ml-2"
                                :label="$t('Encrypted Field')"
                              >
                              </v-switch>-->
                      <v-switch
                        v-if="item.Type == 'datepicker' || item.Type == 'time'"
                        v-model="item.AutoSelectCurrent"
                        :label="
                          item.Type == 'datepicker'
                            ? $t('Auto select date')
                            : $t('Auto select time')
                        "
                        class="mt-0 ml-2"
                      >
                      </v-switch>
                      <v-divider
                        v-if="item.Type == 'number' && item.NeedsNumberSign"
                        class="mb-4"
                      ></v-divider>
                      <v-switch
                        v-if="item.Type == 'number'"
                        v-model="item.NeedsNumberSign"
                        :label="$t('Show currency symbol')"
                        class="mt-0 ml-2"
                      >
                      </v-switch>
                      <v-switch
                        v-if="item.Type == 'number' && item.NeedsNumberSign"
                        v-model="item.IsPrefix"
                        :label="$t('Show currency symbol in front')"
                        class="mt-0 ml-2"
                      >
                      </v-switch>
                      <v-row no-gutters v-if="item.NeedsNumberSign">
                        <v-col cols="10" class="mt-0 ml-2">
                          <v-select
                            v-model="item.NumberSign"
                            :items="$config.signs"
                            dense
                            :label="$t('Symbol')"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-divider
                        class="mb-4"
                        v-if="item.Type == 'number' && item.NeedsNumberSign"
                      ></v-divider>
                      <v-switch
                        v-if="
                          item.Type == 'text' ||
                          item.Type == 'richtext' ||
                          item.Type == 'datepicker' ||
                          item.Type == 'time' ||
                          item.Type == 'dropdown' ||
                          item.Type == 'number'
                        "
                        v-model="item.FieldValidation"
                        @change="$helpers.changeFieldValidation(item)"
                        class="mt-0 ml-2"
                        :label="$t('Field validation')"
                      >
                      </v-switch>
                      <v-switch
                        v-if="item.Type == 'files'"
                        class="mt-0 ml-2"
                        v-model="item.TranslatedFiles"
                        :label="$t('Translate files')"
                      ></v-switch>
                      <v-switch
                        v-if="item.Type == 'files'"
                        class="mt-0 ml-2"
                        v-model="item.SpecificFile"
                        :label="$t('Set file types')"
                      ></v-switch>

                      <v-row no-gutters v-if="item.SpecificFile">
                        <v-col cols="5" class="mt-0 ml-2">
                          <v-select
                            :items="$config.fileTypes"
                            v-model="item.SpecificFileTypes"
                            :label="$t('Files')"
                            multiple
                            chips
                            clearable
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row no-gutters v-if="item.FieldValidation">
                        <v-col cols="8" class="mt-0 ml-2">
                          <v-combobox
                            :items="$validation.getAllValidationNames()"
                            :label="$t('Rule')"
                            v-model="item.FieldValidationFuntion"
                            clearable
                            @click:clear="item.FieldValidationFuntion = ''"
                          >
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="5" class="mt-0 ml-2">
                          <v-text-field
                            v-model.number="item.colWidth"
                            type="number"
                            max="12"
                            min="1"
                            dense
                            :label="$t('Column width')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="d-flex align-center justify-end"
                >
                  <v-btn
                    @click="removeContentDefinitionItem(index)"
                    icon
                    color="red"
                    class="mr-md-3 mr-lg-3 mr-xl-3"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
          </template>
        </v-form>

        <v-card-text class="border-top">
          <h3 class="mb-3 mt-5">
            {{ $t("Add element to content definition") }}
          </h3>

          <addRemovePageElement
            @addItem="addObjectToContentDefinition"
            :enableAdd="true"
          />
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Options") }}</h3>

          <v-switch
            v-model="page.AddSearchField"
            light
            :label="$t('Add search field')"
          ></v-switch>

          <v-switch
            v-model="page.AllowExportForAllUsers"
            light
            :label="$t('Allow export for all users')"
          ></v-switch>

          <v-switch
            v-model="page.ShowSortButtons"
            :disabled="page.MostRecentItemsAtTheTop"
            light
            :label="$t('Show sort buttons')"
          ></v-switch>

          <v-switch
            v-model="page.AllowBulkUpdateForAllUsers"
            light
            :label="$t('Allow bulk update for all users')"
          ></v-switch>
          <v-switch
            v-model="page.MostRecentItemsAtTheTop"
            light
            @change="
              page.MostRecentItemsAtTheTop
                ? $set(page, 'ShowSortButtons', false)
                : ''
            "
            :label="$t('Show most recent items at the top')"
          ></v-switch>
          <v-switch
            v-model="page.ShowCopyBtnForAllUsers"
            light
            :label="$t('Show copy button for all users')"
          ></v-switch>
          <v-switch
            v-model="page.MakeRowsClickable"
            light
            :label="$t('Make rows clickable')"
          ></v-switch>
          <v-row no-gutters>
            <v-col cols="12" md="3" class="d-flex">
              <v-text-field
                v-model="page.DisplayLabelCustomFormat"
                :label="$t('Display label custom format')"
                :placeholder="
                  '{'.charAt(0) +
                  `${$t('Name')}` +
                  '}'.charAt(0) +
                  `${$t('propertyname')}` +
                  '{'.charAt(0) +
                  `${$t('Name')}` +
                  '}'.charAt(0) +
                  `${$t('propertyname')}`
                "
                clearable
              ></v-text-field>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" dark v-bind="attrs" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>

                <span>
                  {{
                    "{".charAt(0) +
                    `${$t("Name")}` +
                    "}".charAt(0) +
                    `${$t("propertyname")}` +
                    "{".charAt(0) +
                    `${$t("Name")}` +
                    "}".charAt(0) +
                    `${$t("propertyname")}`
                  }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Integrations") }}</h3>
          <mailchimp-integration
            :page="page"
            :currentLanguage="currentLanguage"
            @updatePageMailchimpIntegration="page.MailChimpIntegration = $event"
          />
        </v-card-text>
      </v-card>
    </template>
    <errorHandeling
      v-if="setSyncPageSnackbar"
      :buttons="[
        {
          isText: true,
          functionName: '',
          text: 'Sync Items',
        },
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ]"
      :snackbarText="
        $t(
          'Do you wish to synchronize the content with the latest page definitions?'
        )
      "
      @syncItems="setSyncDialog()"
      snackbarColor="warning"
      snackbarTimout="-1"
      snackbarIcon="mdi-alert"
      @clearError="clearSyncWarning()"
    />
    <template v-if="page.RepeatingContent && editMode">
      <cancelConfirmButtons
        :confirmFunction="saveContentDefinition"
        :loading="savingContentDefinition"
        :confirmLabel="$t('Save page definition')"
        @goBack="$emit('goBack')"
      />
    </template>
    <v-dialog v-model="deleteItemDialog" max-width="600px">
      <v-card>
        <v-card-title>
          {{ $t("Delete") }}
        </v-card-title>
        <v-card-text>
          <span
            v-if="itemToDelete.displayLabel"
            v-html="
              $t('Delete page item', { label: itemToDelete.displayLabel })
            "
          ></span>
          <span v-else>
            {{ $t("Are you sure you want to delete the item?") }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="deleteItemDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn :loading="deleting" color="error" @click="deletePageItem()">
            {{ $t("Delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapGetters } from "vuex";
import PageElementFilesDialog from "../shared/pageElementFilesDialog.vue";
export default {
  components: { InfiniteLoading, PageElementFilesDialog },
  props: {
    page: {
      type: [Object, Array],
    },
    linkedPages: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      userIsAdministrator: this.$auth.userIsAdministrator(),
      contentFilterSearchResults: [],
      searchingContentFilters: [],
      searchContentFilterModels: [],
      options: {},
      searchValue: "",
      tableSortBy: "",
      timeFilterValue: null,
      content: null,
      loadinItemToDelete: [],
      deleteItemDialog: false,
      itemToDelete: {},
      deleting: false,
      savingContentDefinition: false,
      tableSortDescending: false,
      addOptionFieldHasError: false,
      newOptionName: "",
      addOptionFieldRule: [],
      setSyncPageSnackbar: false,
      pageFilters: [],
      selectedLang: {},
      showPreview: [],
      isDownloading: [],
      infiniteId: 0,
    };
  },
  created() {
    //Get content filter data
    for (let value in this.page.ContentDefinition) {
      if (
        this.page.ContentDefinition[value].Type == "content" &&
        this.page.ContentDefinition[value].LoadItemsWhenCreated
      ) {
        this.searchingContentFilters[value] = false;
        this.searchContentFilterModels[value] = "";
        this.searchContentFilter(
          this.page.ContentDefinition[value].LinkedPage,
          value
        );
      }
    }
  },
  computed: {
    ...mapGetters({
      error: "error",
      selectedWebsite: "selectedWebsite",
      editMode: "editMode",
    }),
    currentLanguage: {
      get() {
        return this.$i18n.locale();
      },
    },
    tableHeaders() {
      if (this.page.Visualization == "table" && this.page.ContentDefinition) {
        var headers = [];

        if (
          this.userIsAdministrator ||
          (this.page.ShowSortButtons &&
            this.$store.getters.hasUserRoleForPageItem({
              role: 1,
            }))
        ) {
          headers.push({
            text: this.$i18n.translate("Sort"),
            value: "sort",
            sortable: false,
          });
        }

        for (let value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].AddToTableView) {
            if (this.page.ContentDefinition[value].TranslatedTitle) {
              if (
                this.page.ContentDefinition[value].TranslatedTitle[
                  this.currentLanguage
                ] == ""
              ) {
                headers.push({
                  text: this.page.ContentDefinition[value].TranslatedTitle[
                    "Nl"
                  ],
                  value: value,
                });
              } else {
                headers.push({
                  text: this.page.ContentDefinition[value].TranslatedTitle[
                    this.currentLanguage
                  ],
                  value: value,
                });
              }
            } else {
              headers.push({
                text: this.page.ContentDefinition[value].Title,
                value: value,
              });
            }
          }
        }

        headers.push({
          text: this.$i18n.translate("Actions"),
          value: "actions",
          sortable: false,
          align: "right",
        });
        return headers;
      } else {
        return [];
      }
    },
    tableRows() {
      if (this.page.Visualization == "table" && this.content) {
        let rows = [];
        for (let value in this.content) {
          let row = {};
          row["_id"] = this.content[value]._id;
          row["LastUpdated"] = this.content[value].LastUpdated.$date;
          for (let dataValue in this.content[value].Data) {
            row[dataValue] = {
              ...this.content[value].Data[dataValue],
              AddToTableView:
                this.content[value].Data[dataValue].AddToTableView,
            };
          }
          rows.push(row);
        }
        return rows;
      } else {
        return [];
      }
    },
    getPageContentDefinition() {
      let pageContent = {};
      for (const key in this.page.ContentDefinition) {
        if (
          this.page.ContentDefinition[key].ShowFilter &&
          this.page.Visualization == "table"
        ) {
          pageContent[key] = this.page.ContentDefinition[key];
        }
      }
      return pageContent;
    },
  },
  watch: {
    options: {
      handler() {
        if (this.content) {
          this.content = null;
          this.$refs.infiniteLoading.stateChanger.reset();
          this.getContent();
        }
      },
    },
  },

  methods: {
    getValue(index, val, item) {
      const itemsLenght = this.getTableContentItems(item).length - 1;
      val = val.replace(/\s/g, "");
      if (itemsLenght == 0) {
        return val;
      }
      return index !== itemsLenght ? val + "," : val;
    },
    getTableContentItems(item) {
      if (!item.ValueSort) return [];
      if (item.ValueSort) {
        return item.ValueSort.split(",");
      }
    },
    getAllFilesFromItem(files) {
      if (!files) return;
      let allFiles = [];
      if (files[this.currentLanguage]) {
        files[this.currentLanguage].forEach((element) => {
          allFiles.push(element);
        });
      } else {
        files.forEach((element) => {
          allFiles.push(element);
        });
      }

      return allFiles;
    },
    handleClick(row, event2, nativeEvent) {
      if (nativeEvent.target.classList.contains("v-icon")) return;
      if (this.page.MakeRowsClickable) {
        this.$router.push({
          name: "editPageAddEditItem",
          params: { id: this.page._id.$oid, itemId: row._id.$oid },
        });

        this.$router.go(1);
      }
    },
    addObjectToContentDefinition(properties) {
      var newObject = {
        Title: properties.objectTitle,
        Value: this.$helpers.createEmptyValueBasedOnType(properties.objectType),
        Type: properties.objectType,
        Translate: properties.objectLanguages,
        Options: [],
        Properties: [],
      };
      if (
        properties.objectType == "text" ||
        properties.objectType == "richtext" ||
        properties.objectType == "datepicker" ||
        properties.objectType == "time" ||
        properties.objectType == "dropdown" ||
        properties.objectType == "content"
      ) {
        this.$set(newObject, "FieldValidation", true);
        this.$set(newObject, "FieldValidationFuntion", "notEmptyStringRule");
      }

      if (
        properties.objectType == "image" ||
        properties.objectType == "croppedimage" ||
        properties.objectType == "gallery"
      ) {
        newObject["AddAltTags"] = "true";
      }

      this.$set(this.page.ContentDefinition, properties.objectName, newObject);
    },
    addOption(index) {
      this.addOptionFieldHasError = false;
      this.addOptionFieldRule = [];

      if (this.newOptionName.length > 1) {
        this.page.ContentDefinition[index].Options.push({
          Id: this.page.ContentDefinition[index].Options.length + 1,
          Value: this.newOptionName,
          Color: this.newOptionColor,
        });
      } else {
        this.addOptionFieldRule = [
          () => this.$i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addOptionFieldHasError = true;
      }
    },
    moveUp(index) {
      var newSort;
      var oldSort = this.content[index].Sort;

      if (index == 0) {
        newSort = this.content[this.content.length - 1].Sort;
      } else {
        newSort = this.content[index - 1].Sort;
      }

      if (newSort == oldSort) {
        newSort += 1;
      }

      var indexToSwapWith = index - 1;

      if (index == 0) {
        indexToSwapWith = this.content.length - 1;
      }

      this.content[index].Sort = newSort;
      this.content[indexToSwapWith].Sort = oldSort;

      this.updateContentSort([
        this.content[index],
        this.content[indexToSwapWith],
      ]);
    },
    moveDown(index) {
      var newSort;
      var oldSort = this.content[index].Sort;

      if (index == this.content.length - 1) {
        newSort = this.content[0].Sort;
      } else {
        newSort = this.content[index + 1].Sort;
      }

      if (newSort == oldSort) {
        newSort -= 1;
      }
      var indexToSwapWith = index + 1;

      if (indexToSwapWith == this.content.length) {
        indexToSwapWith = 0;
      }

      this.content[index].Sort = newSort;
      this.content[indexToSwapWith].Sort = oldSort;

      this.updateContentSort([
        this.content[index],
        this.content[indexToSwapWith],
      ]);
    },
    updateContentSort(items) {
      this.$request.put("/content/updatesort", items, (res) => {
        if (res.success) {
          this.content = this.content.sort(function (a, b) {
            return a.Sort - b.Sort;
          });
        } else {
          this.$store.dispatch("setError", res);
        }
      });
    },

    getTableDatepicker(item) {
      if (item.Value == "") {
        return "00-00-0000";
      }
      const [year, month, day] = item.Value.split("-");
      return day + "-" + month + "-" + year;
    },
    getBooleanValues() {
      return [
        { label: this.$i18n.translate("Yes"), value: true },
        { label: this.$i18n.translate("No"), value: false },
      ];
    },
    showItem(item) {
      let returnValue = item;
      if (item["DisplayLabels"]) {
        returnValue = item.DisplayLabels[this.currentLanguage]
          ? item.DisplayLabels[this.currentLanguage]
          : item.DisplayLabels["Nl"];
      } else if (item["DisplayLabel"]) {
        returnValue = item.DisplayLabel;
      }
      return returnValue;
    },
    getTranslatedItemTitle(item) {
      if (!item.TranslatedTitle) {
        return item.Title;
      }
      return item.TranslatedTitle[this.currentLanguage]
        ? item.TranslatedTitle[this.currentLanguage]
        : item.TranslatedTitle["Nl"];
    },
    customSort: function (items) {
      return items;
    },
    moveUpContentDefinition(index) {
      let keyOld;
      let keys = Object.keys(this.page.ContentDefinition);

      keys.forEach((element, i) => {
        if (element == index && i != 0) {
          keyOld = element;
          keys[i] = keys[i - 1];
          keys[i - 1] = keyOld;
        }
      });
      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.page.ContentDefinition[element];
      });
      this.page.ContentDefinition = newObject;
    },
    moveDownContentDefinition(index) {
      let keyOld;
      let keys = Object.keys(this.page.ContentDefinition);
      let isSet = false;
      keys.forEach((element, i) => {
        if (element == index && i !== keys.length - 1 && !isSet) {
          keyOld = element;
          keys[i] = keys[i + 1];
          keys[i + 1] = keyOld;
          isSet = true;
        }
      });

      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.page.ContentDefinition[element];
      });
      this.page.ContentDefinition = newObject;
    },
    getSharedPagesNotAssigned() {
      return this.selectedWebsite.Pages.filter(
        (p) => p.RepeatingContent === true
      );
    },
    getContent($state) {
      if (!this.contentIsLoading) {
        this.contentIsLoading = true;
        if (this.options.sortBy) {
          if (this.options.sortBy.length > 0) {
            this.tableSortBy = this.options.sortBy[0];
          }

          if (this.options.sortDesc.length > 0) {
            this.tableSortDescending = this.options.sortDesc[0];
            if (!this.options.sortDesc[0] && this.tableSortBy == "") {
              this.tableSortDescending =
                this.page.MostRecentItemsAtTheTop ?? false;
            }
          } else if (
            this.options.sortBy.length == 0 &&
            this.tableSortBy != ""
          ) {
            this.tableSortDescending = !this.tableSortDescending;
          } else if (
            this.options.sortDesc.length == 0 &&
            this.page.MostRecentItemsAtTheTop &&
            this.tableSortBy == ""
          ) {
            this.tableSortDescending =
              this.page.MostRecentItemsAtTheTop ?? false;
          }
        }

        var urlParameters =
          this.$route.params.id +
          "?start=" +
          (this.content == null ? 0 : this.content.length) +
          "&sortBy=" +
          this.tableSortBy +
          "&sortDescending=" +
          this.tableSortDescending +
          "&search=" +
          (this.searchValue == null ? "" : this.searchValue) +
          this.getPageFiltersQueryString();

        var getContentUrl =
          "/" +
          (this.page.Visualization == "table" ? "" : "minified") +
          "pagecontent/" +
          urlParameters;
        var getContentCountUrl = "/pagecontentcount/" + urlParameters;

        this.$request.getAll(
          [getContentCountUrl, getContentUrl],
          null,
          (res) => {
            if (res[0]) {
              this.infiniteId += 1;
              if (res[0].success) {
                this.page.ContentCount = res[0].data;
                if (res[1].success) {
                  if (this.content == null) {
                    this.content = res[1].data;
                  } else if (this.content.length < this.page.ContentCount) {
                    this.content = this.content.concat(res[1].data);
                  }

                  this.$refs.infiniteLoading.stateChanger.loaded();

                  if (this.content.length == this.page.ContentCount && $state) {
                    this.$refs.infiniteLoading.stateChanger.complete();
                  }

                  if (this.tableSortBy != "" && this.options.sortBy) {
                    this.options.sortDesc[0] = this.tableSortDescending;
                    this.options.sortBy[0] = this.tableSortBy;
                  }

                  this.contentIsLoading = false;
                } else {
                  this.$store.dispatch("setError", res[1]);
                  this.contentIsLoading = false;
                }
              } else {
                this.$store.dispatch("setError", res[0]);
                this.contentIsLoading = false;
              }
            } else {
              this.$store.dispatch("setError", res);
              this.contentIsLoading = false;
            }
          }
        );
      }
    },
    getPageFiltersQueryString() {
      var filters = "";
      for (var filter in this.pageFilters) {
        if (
          this.pageFilters[filter] != null &&
          this.pageFilters[filter] != undefined
        ) {
          if (Array.isArray(this.pageFilters[filter])) {
            for (var currentFilterIndex in this.pageFilters[filter]) {
              filters +=
                "filters=" +
                filter +
                ":" +
                this.pageFilters[filter][currentFilterIndex]._id.$oid +
                "&";
            }
          } else if (this.pageFilters[filter].$oid) {
            filters +=
              "filters=" + filter + ":" + this.pageFilters[filter].$oid + "&";
          } else {
            filters +=
              "filters=" + filter + ":" + this.pageFilters[filter] + "&";
          }
        }
      }
      return "&" + filters;
    },
    getTableDropdown(item, needsColor = false) {
      if (!item.Value) {
        return "";
      }
      let result = item.Options.filter((obj) => {
        return obj.Id == item.Value;
      });
      if (result.length > 0) {
        if (needsColor) {
          return result[0].Color;
        }
        return result[0].Value;
      }
      return "";
    },
    getTableText(item) {
      let dataValueLanguage = !item["Translate"] ? "Nl" : this.currentLanguage;
      if (item.Value[dataValueLanguage] == undefined) {
        return item.Value;
      } else {
        let text = item.Value[dataValueLanguage].replace(/<[^>]*>?/gm, "");
        if (text.length > 80) {
          return text.substr(0, 80) + "...";
        } else {
          return text;
        }
      }
    },
    removeOption(index, optionIndex) {
      this.page.ContentDefinition[index].Options.splice(optionIndex, 1);
    },
    saveContentDefinition() {
      if (this.$refs.contentDefinitionform.validate()) {
        //used when saving the content definition
        this.savingContentDefinition = true;
        //if there is no TranslatedTitle in object add one with title
        for (const key in this.page.ContentDefinition) {
          if (!this.page.ContentDefinition[key]["TranslatedTitle"]) {
            this.page.ContentDefinition[key]["TranslatedTitle"] = {
              Nl: "",
              Fr: "",
              En: "",
            };
            this.page.ContentDefinition[key]["TranslatedTitle"][
              this.selectedLang[key]
            ] = this.page.ContentDefinition[key].Title;
          }
        }
        this.$request.put("/page", this.page, (res) => {
          this.savingContentDefinition = false;

          if (res.success) {
            this.$store.dispatch("setEditMode", false);

            if (this.page.ContentCount != 0) {
              this.setSyncPageSnackbar = true;
            }
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      }
    },
    setDeletingItem(id, itemId, index) {
      this.$set(this.loadinItemToDelete, index, true);
      this.$request.get("/content/" + itemId, null, (res) => {
        if (res.success) {
          this.itemToDelete = {
            displayLabel: res.data.DisplayLabel,
            id: id,
            itemId: itemId,
          };
          this.deleteItemDialog = true;
        } else {
          this.$store.dispatch("setError", res);
        }
        this.$set(this.loadinItemToDelete, index, false);
      });
    },
    getContentFromLinkedPage(item) {
      if (item.$oid) {
        this.$request.get("/page/" + item.$oid, null, (res) => {
          if (res.success) {
            let arr = [];
            for (const key in res.data.ContentDefinition) {
              if (res.data.ContentDefinition[key].Type === "colorpicker") {
                const lang = res.data.ContentDefinition[key].TranslatedTitle[
                  this.currentLanguage
                ]
                  ? res.data.ContentDefinition[key].TranslatedTitle[
                      this.currentLanguage
                    ]
                  : res.data.ContentDefinition[key].TranslatedTitle["Nl"];
                arr.push({ id: key, value: lang });
              }
            }
            this.linkedPageContentItems = arr;
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      }
    },
    getFavicon(item) {
      return this.$helpers.getFaviconFromGoogle(item);
    },
    getDomainUrl(item) {
      return this.$helpers.getDomainUrl(item);
    },
    searchContentFilter(linkedPage, index) {
      if (this.searchingContentFilters[index]) return;
      if (!linkedPage || !this.$auth.loggedIn()) return;

      this.searchingContentFilters[index] = true;

      this.$request.get(
        "/pagecontentsearch/" +
          linkedPage.$oid +
          "?searchValue=" +
          this.searchContentFilterModels[index] +
          "&parentPageId=" +
          this.page._id.$oid,
        null,
        (res) => {
          if (res.success) {
            if (!res.data.length) {
              this.$set(this.searchingContentFilters, index, false);
              return;
            }
            this.$set(this.contentFilterSearchResults, index, res.data);
            if (this.$refs["autocomplete"]) {
              this.$refs["autocomplete" + index][0].cachedItems = [];
            }
          } else {
            this.$store.dispatch("setError", res);
          }
          this.$set(this.searchingContentFilters, index, false);
        }
      );
    },
    onSearchCleared() {
      this.searchValue = "";
      this.content = null;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.getContent();
    },
    searchContent() {
      var scope = this;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.content = null;
        scope.$refs.infiniteLoading.stateChanger.reset();
        scope.getContent();

        clearTimeout(this.searchTimeout);
      }, 800);
    },
    filterChanged(value, filterOn) {
      this.pageFilters[filterOn] = value;
      this.content = null;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.getContent();
    },
    deletePageItem() {
      this.deleting = true;

      if (
        this.page.SynchronizationDeleteUrl &&
        this.page.SynchronizationDeleteUrl.length > 0
      ) {
        this.$request.delete(
          this.page.SynchronizationDeleteUrl + "/" + this.itemToDelete.itemId,
          null,
          (res) => {
            if (!res.success) {
              this.$store.dispatch("setError", res);
            }
            this.$request.delete(
              "/content/" + this.itemToDelete.itemId,
              null,
              (res) => {
                this.deleting = false;

                if (res.success) {
                  const filterdContent = this.content.filter(
                    (el) => el._id.$oid !== this.itemToDelete.itemId
                  );
                  this.content = filterdContent;

                  this.deleteItemDialog = false;
                } else {
                  this.$store.dispatch("setError", res);
                }
              }
            );
          }
        );
      } else {
        this.$request.delete(
          "/content/" + this.itemToDelete.itemId,
          null,
          (res) => {
            this.deleting = false;

            if (res.success) {
              const filterdContent = this.content.filter(
                (el) => el._id.$oid !== this.itemToDelete.itemId
              );
              this.content = filterdContent;
              this.page.ContentCount -= 1;
              this.deleteItemDialog = false;
            } else {
              this.$store.dispatch("setError", res);
            }
          }
        );
      }
    },
    getTableNumber(item) {
      if (item["IsPercentage"]) {
        return item.Value + " %";
      }
      return item.Value;
    },
    removeContentDefinitionItem(index) {
      this.$delete(this.page.ContentDefinition, index);
    },

    openInNewTab(item) {
      if (!item) return;
      window.open(item, "_blank").focus();
    },
    setSyncDialog() {
      this.$refs.RepeatingContentButtons.setDialog();
      this.$refs.RepeatingContentButtons.syncAllItems();
      this.setSyncPageSnackbar = false;
    },
    clearSyncWarning() {
      this.setSyncPageSnackbar = false;
      this.$store.dispatch("resetError");
    },
    downloadFile(file) {
      this.isDownloading = true;
      this.$request.download(
        "/files/" +
          this.page.WebsiteId.$oid +
          "/" +
          file +
          "?token=" +
          this.$auth.userToken(),
        { responseType: "blob" },
        file,
        () => {
          this.isDownloading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.sort-button {
  margin-top: 5px;
}
.cursor-pointer {
  cursor: pointer;
}
@media only screen and (max-width: 495px) {
  .sort-button {
    margin-top: -3px;
  }
}
.sort-button {
  margin-top: 5px;
}

.max-width-layout {
  max-width: 30px;
}
</style>